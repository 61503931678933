
const nonPublic = () => {
	return {
			nonPublic: true,
			name: "Privat", 
			email: "kaninregistret@skvf.se"
		};
}

export { 
	nonPublic
}